import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import initMenu from 'src/constants/menu';
import { useProfileState } from 'src/providers/ProfileProvider';
import {
  useFeature,
  useFeatureCallback,
  useFeatureLoadingState,
} from 'src/providers/FeaturesProvider';
import { FEATURE } from 'src/constants/enums';
import { ROUTE_REFERRALS } from 'src/constants/routes';

const useMenuItems = () => {
  const intl = useIntl();
  const { profile } = useProfileState();
  const featureCallback = useFeatureCallback();
  const isFeaturesLoaded = useFeatureLoadingState();
  const featureReferrals = useFeature(FEATURE.REFERRALS);

  return useMemo(
    () =>
      initMenu({
        intl,
      })
        .filter((item) =>
          item.to === ROUTE_REFERRALS ? !!featureReferrals : true
        )
        .filter((item) => !item.needAuth || !!profile)
        .filter((item) =>
          item?.accessBy ? featureCallback(item.accessBy) : true
        ),
    [isFeaturesLoaded, profile]
  );
};

export default useMenuItems;
